import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../../services/alert.service';
import {AuthService} from '../../../services/auth.service';
import {RegisterModel} from '../../../../../../shared/models/register.model';

@Component({
  selector: 'app-login',
  templateUrl: './register.component.html',
  styleUrls: ['./register.scss']
})
export class RegisterComponent implements OnInit {

  registerModel: RegisterModel;

  repeatPassword: string;

  loading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
  ) {

  }

  ngOnInit() {
    this.registerModel = {
      email: '',
      password: '',
      forename: '',
      surname: ''
    };
  }

  register() {
    this.loading = true;
    this.authService.register(this.registerModel).subscribe(() => {
      this.authService.loginWithEmail(this.registerModel.email, this.registerModel.password).then(() => {
        this.alertService.showAlert('register.success', 5000);
      });
    }, err => {
      this.loading = false;
      this.alertService.showAlert('register.errors.' + err.error.code, 5000);
    });
  }

  cancel() {
    this.router.navigate(['']);
  }
}
