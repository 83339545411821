import {FirestoreService} from './firestore.service';
import {AccumulationModel} from '../../../../shared/models/accumulation.model';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({providedIn: 'root'})
export class AccumulationService extends FirestoreService<AccumulationModel> {

  WG_COLLECTION = 'wgs';

  ACCUMULATION_COLLECTION = 'accumulations';

  accumulations: AccumulationModel;

  constructor(
    protected store: AngularFirestore
  ) {
    super(store);
  }

  public cancelSubscription() {
    this.accumulations = null;
    super.cancelSubscription();
  }

  getAccumulations() {
    return this.accumulations;
  }

  loadAccumulations(wgId: string, uid: string): Promise<any> {
    return new Promise<any>(resolve => {
      if (this.accumulations) {
        resolve();
        return;
      }

      this.subscription.push(super.get([wgId, uid], [this.WG_COLLECTION, this.ACCUMULATION_COLLECTION])
        .subscribe((accumulations: AccumulationModel) => {
        this.accumulations = accumulations;
        resolve();
      }));
    });
  }
}
