import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-first-view',
  templateUrl: './first-view.component.html',
  styleUrls: ['./first-view.component.scss']
})
export class FirstViewComponent implements OnInit, OnChanges {

  @Input()
  message: string;

  @Input()
  buttonText: string;

  @Input()
  title: string;

  @Input()
  src: string;

  @Output()
  buttonEvent: EventEmitter<void> = new EventEmitter();

  translatedMessage: string;
  translatesButtonText: string;
  translatedTitle: string;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.translateService.get([this.message, this.buttonText, this.title]).subscribe(translation => {
      this.translatedMessage = translation[this.message];
      this.translatesButtonText = translation[this.buttonText];
      this.translatedTitle = translation[this.title];
    });
  }

}
