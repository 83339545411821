import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';

import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';

import {SharedModule} from './shared/shared.module';
import {OverviewModule} from './pages/overview/overview.module';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconRegistry} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TRANSLATE_CONFIG} from './shared/config/translation.config';
import {AlertService} from './services/alert.service';
import {AccountModule} from './pages/account/account.module';
import {SettingsModule} from './pages/settings/settings.module';
import {WgModule} from './pages/wg/wg.module';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PaymentsModule} from './pages/payments/payments.module';
import {FooterComponent} from './layout/footer/footer.component';
import {ImpressumModule} from './pages/impressum/impressum.module';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    AccountModule,
    OverviewModule,
    SettingsModule,
    PaymentsModule,
    WgModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    TranslateModule.forRoot(TRANSLATE_CONFIG),
    MatSnackBarModule,
    MatCardModule,
    MatListModule,
    MatChipsModule,
    MatButtonModule,
    MatProgressBarModule,
    ImpressumModule
  ],
  providers: [
    AlertService,
    DatePipe
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/mdi.svg'));
  }
}
