import {Routes} from '@angular/router';
import {SettingsComponent} from './settings.component';
import {AuthGuard} from '../../guards/auth.guard';

export const settingsRoute: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full',
  }
];
