import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {UserModel} from '../../../../../../shared/models/user.model';
import {AlertService} from '../../../services/alert.service';
import {PaymentModel} from '../../../../../../shared/models/payment.model';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../services/payment.service';
import {WgMemberModel} from '../../../../../../shared/models/wg-member.model';
import {WgMemberService} from '../../../services/wg-member.service';
import {RolesEnum} from '../../../../../../shared/enum/roles.enum';
import {AccumulationService} from '../../../services/accumulation.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ImageDialogComponent} from 'src/app/shared/image-dialog/image-dialog.component';
import {take} from 'rxjs/operators';
import {EntityFactoryService} from '../../../services/entity-factory.service';
import {EntitiesEnum} from '../../../../../../shared/enum/entities.enum';
import {YesNoDialogComponent} from '../../../shared/yes-no-dialog/yes-no-dialog.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-add-payments',
  templateUrl: './add-payments.component.html',
  styleUrls: ['./add-payments.component.scss']
})
export class AddPaymentsComponent implements OnInit {

  payment: PaymentModel = {};

  amount: number;

  date: Date;

  loading = false;

  RolesEnum = RolesEnum;

  billImage: any;

  emptyImage = 'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNsrgcAAYsBBMNqZDYAAAAASUVORK5CYII=';

  constructor(
    private wgMemberService: WgMemberService,
    private userService: UserService,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private paymentService: PaymentService,
    private activatedRoute: ActivatedRoute,
    private accumulationService: AccumulationService,
    private dialog: MatDialog,
    private entityFactory: EntityFactoryService,
    private translateService: TranslateService
  ) { }

  get canEdit(): boolean {
    return (this.payment && this.user && this.payment.from && this.user.id) ? this.payment.from === this.user.id : false;
  }

  get validTo(): boolean {
    if (!this.payment || !this.payment.to) {
      return false;
    }

    if (this.payment.to.length !== 1) {
      return true;
    }

    return this.payment.to[0] !== this.user.email;
  }

  async ngOnInit(): Promise<void> {
    this.date = new Date();
    await this.entityFactory.loadEntities([EntitiesEnum.USER, EntitiesEnum.WG_MEMBER]);

    this.loadPayment();
    this.setRecipients();
  }

  loadPayment() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.paymentService.getPayment(this.user.wgId, params.id).pipe(take(1)).subscribe((payment: PaymentModel) => {
          this.payment = payment;
          const date = new Date();
          date.setTime(payment.date * 1000);
          this.date = date;
          this.amount = this.payment.amount / 100;
        });
      } else {
        this.payment = {
          date: new Date().getTime(),
          from: this.user.id
        };
      }
    });
  }

  async savePayment(): Promise<void> {
    this.loading = true;
    this.payment.date = Math.round(this.date.getTime() / 1000);
    this.payment.amount = this.amount * 100;
    this.payment.from = this.user.id;

    if (!this.payment.id) {
      await this.paymentService.createPayment(this.user.wgId, this.payment, this.billImage);
    } else {
      await this.paymentService.updatePayment(this.user.wgId, this.payment, this.billImage);
    }

    this.router.navigate(['/overview']);
  }

  showImageDialog(): void {
    if (!this.payment.billImage) {
      return;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        image: this.payment.billImage
    };

    this.dialog.open(ImageDialogComponent, dialogConfig);
  }

  deletePayment() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: this.translateService.instant('payments.delete.dialog.title'),
      message: this.translateService.instant('payments.delete.dialog.message')
    };

    const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.loading = true;
        await this.paymentService.deletePayment(this.user.wgId, this.payment.id);
        this.router.navigate(['overview']);
      }
    });
  }

  get user(): UserModel {
    return this.userService.getUser();
  }

  get members(): WgMemberModel[] {
    return this.wgMemberService.getMembers();
  }

  private setRecipients() {
    this.payment.to = [];
    this.members.forEach(member => {
      if (member.role !== RolesEnum.DELETED) {
        this.payment.to.push(member.id);
      }
    });
  }

  fileChange(fileInput): void {
    const files = fileInput.files;
    if (files.length) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.payment.billImage = fileReader.result;
      };
      fileReader.readAsDataURL(files[0]);
    }
  }
}
