import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {overviewRoutes} from './pages/overview/overview.route';
import {accountRoutes} from './pages/account/account.route';
import {settingsRoute} from './pages/settings/settings.route';
import {wgRoutes} from './pages/wg/wg.route';
import {notificationsRoutes} from './pages/notifications/notifications.route';
import {paymentsRoute} from './pages/payments/payments.route';
import { impressumRoutes } from './pages/impressum/impressum.route';

const routes: Routes = [
  ...accountRoutes,
  ...overviewRoutes,
  ...settingsRoute,
  ...wgRoutes,
  ...notificationsRoutes,
  ...paymentsRoute,
  ...impressumRoutes
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
