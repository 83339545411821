import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WgCreateComponent} from './wg-create/wg-create.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
import {CustomFormsModule} from 'ng2-validation';
import {WgManageComponent} from './wg-manage/wg-manage.component';
import {WgManageUserComponent} from './wg-manage/wg-manage-user/wg-manage-user.component';

@NgModule({
  declarations: [
    WgCreateComponent,
    WgManageComponent,
    WgManageUserComponent],
  exports: [
    WgCreateComponent,
    WgManageComponent,
    WgManageUserComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    MatInputModule,
    FormsModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatDividerModule,
    MatProgressBarModule,
    TextareaAutosizeModule,
    MatProgressSpinnerModule,
    CustomFormsModule
  ]
})
export class WgModule { }
