import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../../services/alert.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email = '';
  password = '';
  loading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService
  ) {

  }

  ngOnInit(): void {
  }

  login(): void {
    this.loading = true;
    this.authService.loginWithEmail(this.email, this.password).catch(err => {
      this.loading = false;
      this.alertService.showAlert('login.errors.' + err.code, 5000);
    });
  }

}
