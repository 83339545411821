import {Routes} from '@angular/router';
import {AddPaymentsComponent} from './add-payment/add-payments.component';
import {AuthGuard} from '../../guards/auth.guard';
import {PaymentsOverviewComponent} from './payments-overview/payments-overview.component';

export const paymentsRoute: Routes = [
  {
    path: 'payments/create',
    component: AddPaymentsComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full',
  },
  {
    path: 'payments/view/:id',
    component: AddPaymentsComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full',
  },
  {
    path: 'payments/overview',
    component: PaymentsOverviewComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full',
  }
];
