import {Component, Input, OnChanges} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {InviteService} from '../../services/invite.service';
import {UserService} from '../../services/user.service';
import {AlertService} from '../../services/alert.service';
import {UserModel} from '../../../../../shared/models/user.model';
import {WgService} from 'src/app/services/wg.service';
import {WgModel} from '../../../../../shared/models/wg.model';
import {WgMemberService} from '../../services/wg-member.service';
import {AccumulationService} from '../../services/accumulation.service';
import {EntityFactoryService} from '../../services/entity-factory.service';
import {EntitiesEnum} from '../../../../../shared/enum/entities.enum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnChanges {

  @Input()
  loaded = false;

  dataLoaded = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private invitesService: InviteService,
    private alertService: AlertService,
    private wgService: WgService,
    private wgMemberService: WgMemberService,
    private accumulationService: AccumulationService,
    private entityFactory: EntityFactoryService
  ) {
  }

  get user(): UserModel {
    return this.userService.getUser();
  }

  logout() {
    this.cancelSubscriptions();
    this.authService.signOut();
  }

  settings() {
    this.router.navigate(['/settings']);
  }

  startPage() {
    this.router.navigate(['']);
  }

  notifications() {
    this.router.navigate(['/notifications']);
  }

  cancelSubscriptions() {
    this.accumulationService.cancelSubscription();
    this.invitesService.cancelSubscription();
    this.userService.cancelSubscription();
    this.wgService.cancelSubscription();
    this.wgMemberService.cancelSubscription();
  }

  get wg(): WgModel {
    return this.wgService.getWg();
  }

  get notificationCount(): number {
    return this.invitesService.getInvites() ? Object.keys(this.invitesService.getInvites()).length : 0;
  }

  async ngOnChanges() {
    if (!this.loaded) {
      return;
    }

    await this.entityFactory.loadEntities([EntitiesEnum.USER, EntitiesEnum.WG, EntitiesEnum.NOTIFICATIONS]);
    this.dataLoaded = true;
  }
}
