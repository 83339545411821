import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from '../layout/navbar/navbar.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FirstViewComponent} from '../pages/first-view/first-view.component';
import {BackComponent} from '../layout/back/back.component';
import {ImageDialogComponent} from './image-dialog/image-dialog.component';
import {YesNoDialogComponent} from './yes-no-dialog/yes-no-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';


@NgModule({
  declarations: [
    NavbarComponent,
    BackComponent,
    FirstViewComponent,
    ImageDialogComponent,
    YesNoDialogComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    RouterModule,
    MatBadgeModule,
    MatDialogModule,
    MatChipsModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatRadioModule,
    FormsModule,
    MatDatepickerModule,
    MatTableModule
  ],
  exports: [
    NavbarComponent,
    BackComponent,
    FirstViewComponent,
    ImageDialogComponent,
    YesNoDialogComponent
  ],
  entryComponents: [ImageDialogComponent, YesNoDialogComponent]
})
export class SharedModule {
}
