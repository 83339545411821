import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverviewComponent} from './overview.component';
import {SharedModule} from '../../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MembersBillingOverviewComponent} from './members-billing-overview/members-billing-overview.component';
import {LastPaymentsComponent} from './last-payments/last-payments.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    OverviewComponent,
    LastPaymentsComponent,
    MembersBillingOverviewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    TranslateModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RouterModule
  ]
})
export class OverviewModule {
}
