import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {AlertService} from '../../services/alert.service';
import {WgService} from '../../services/wg.service';
import {EntityFactoryService} from '../../services/entity-factory.service';
import {EntitiesEnum} from '../../../../../shared/enum/entities.enum';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  loaded = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private wgService: WgService,
    private entityFactory: EntityFactoryService
  ) {
  }

  get user() {
    return this.userService.getUser();
  }

  get wg() {
    return this.wgService.getWg();
  }

  async ngOnInit(): Promise<void> {
    await this.entityFactory.loadEntities([EntitiesEnum.USER, EntitiesEnum.WG_MEMBER, EntitiesEnum.WG]);
    this.loaded = true;
  }
}
