import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserModel} from '../../../../../../shared/models/user.model';
import {WgMemberModel} from '../../../../../../shared/models/wg-member.model';
import {RolesEnum} from '../../../../../../shared/enum/roles.enum';
import {AccumulationModel} from '../../../../../../shared/models/accumulation.model';

@Component({
  selector: 'app-members-billing-overview',
  templateUrl: './members-billing-overview.component.html',
  styleUrls: ['./members-billing-overview.component.scss']
})
export class MembersBillingOverviewComponent implements OnInit, OnChanges {

  @Input()
  user: UserModel;

  @Input()
  members: WgMemberModel[];

  @Input()
  accumulations: AccumulationModel;

  @Input()
  loading = true;

  RolesEnum = RolesEnum;

  accumulationSum = 0;

  ngOnInit() {

  }

  getPriceText(price: number) {
    return (price / 100.0).toFixed(2) + ' €';
  }

  getAccumulation(uid: string) {
    return (this.accumulations && this.accumulations[uid]) ? this.accumulations[uid] : 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.members) {
      return;
    }

    this.members = this.members.sort((a: WgMemberModel, b: WgMemberModel) => {
      if (a.id === this.user.id && b.id !== this.user.id) {
        return -1;
      } else {
        return 1;
      }
    });

    this.accumulationSum = 0;

    if (!this.accumulations) {
      return;
    }

    for (const key of Object.keys(this.accumulations)) {
      this.accumulationSum += this.accumulations[key];
    }
  }
}
