import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient} from '@angular/common/http';
import {WgModel} from '../../../../shared/models/wg.model';
import {AuthService} from './auth.service';
import {CLOUDFUNCTION_URL} from '../shared/config/cloudfunction.config';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class WgService extends FirestoreService<WgModel> {

  COLLECTION = 'wgs';

  private wg: WgModel;

  constructor(
    protected store: AngularFirestore,
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    super(store);
  }

  public cancelSubscription() {
    this.wg = null;
    super.cancelSubscription();
  }

  public getWg(): WgModel {
    return this.wg;
  }

  public getExternalWg(id: string): Observable<WgModel> {
    return super.get([id], [this.COLLECTION]).pipe(take(1));
  }

  public loadWg(id: string) {
    return new Promise<any>(resolve => {
      if (this.wg) {
        resolve();
        return;
      }

      this.subscription.push(super.get([id], [this.COLLECTION]).subscribe((wg: WgModel) => {
        this.wg = wg;
        resolve();
      }));
    });
  }

  public updateWg(wg: WgModel) {
    return super.update(wg, [wg.id], [this.COLLECTION]);
  }

  public createWg(wg: WgModel, id: string) {
    return super.createWithId(wg, id, [], [this.COLLECTION]);
  }

  public async handleInviteAction(wgId: string, accept: boolean) {
    const options = await this.authService.generateAuthHeader();
    const action = accept ? 'acceptInvite' : 'declineInvite';

    return this.httpClient.post(CLOUDFUNCTION_URL + action, {
      wgId
    }, options).toPromise();
  }
}
