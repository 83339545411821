import {Routes} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import { ImpressumComponent } from './impressum.component';

export const impressumRoutes: Routes = [
  {
    path: 'impressum',
    component: ImpressumComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full'
  }
];
