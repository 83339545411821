import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SettingsComponent} from './settings.component';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {SettingsWgComponent} from './wg/settings-wg.component';
import {SettingsPersonalComponent} from './personal/settings-personal.component';
import {SharedModule} from '../../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {CustomFormsModule} from 'ng2-validation';
import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
import {WgModule} from '../wg/wg.module';
import {MatIconModule} from '@angular/material/icon';
import {MaterialFileInputModule} from 'ngx-material-file-input';

@NgModule({
  declarations: [
    SettingsComponent,
    SettingsWgComponent,
    SettingsPersonalComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatDividerModule,
    MatIconModule,
    MaterialFileInputModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    CustomFormsModule,
    MatProgressSpinnerModule,
    TextareaAutosizeModule,
    WgModule,
    SharedModule
  ]
})
export class SettingsModule {
}
