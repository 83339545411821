import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {CLOUDFUNCTION_URL} from '../shared/config/cloudfunction.config';
import {UserModel} from '../../../../shared/models/user.model';
import {StorageService} from './storage.service';
import {AngularFireFunctions} from '@angular/fire/functions';

@Injectable({providedIn: 'root'})
export class UserService extends FirestoreService<UserModel> {

  COLLECTION = 'users';

  private user: UserModel;

  constructor(
    protected store: AngularFirestore,
    private functions: AngularFireFunctions,
    private storageService: StorageService,
    private authService: AuthService,
    private httpClient: HttpClient) {
    super(store);
  }

  public cancelSubscription() {
    this.user = null;
    super.cancelSubscription();
  }

  public getUser(): UserModel {
    return this.user;
  }

  public loadUser() {
    return new Promise<any>(resolve => {
      if (this.user) {
        resolve();
        return;
      }

      this.subscription.push(super.get([this.authService.currentUserId()], [this.COLLECTION]).subscribe((user: UserModel) => {
        this.user = user;
        resolve();
      }, error => {
        console.log('user');
      }));
    });
  }

  public async updateUser(user: UserModel, image?: any) {
    if (image) {
      user.avatar = await this.storageService.uploadImage(image, `users/${user.email}/avatar`);
    }

    return super.update(user, [user.id], [this.COLLECTION]);
  }

  public async userExists(email: string): Promise<any> {
    const params = {
      email: encodeURIComponent(email)
    };

    const options = await this.authService.generateAuthHeader(params);
    return this.httpClient.get(CLOUDFUNCTION_URL + 'userExist', options).toPromise();
  }
}
