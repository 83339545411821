import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguagesEnum} from './shared/config/languages.enum';
import {AuthService} from './services/auth.service';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private dateAdapter: DateAdapter<Date>
  ) {
    translateService.setDefaultLang(LanguagesEnum.GERMAN);
    this.dateAdapter.setLocale('de');
  }

  get loaded() {
    return this.authService.isLoaded();
  }

  isLoginScreen() {
    return !this.authService.isUserEmailLoggedIn();
  }
}
