import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {WgInvitesModel} from '../../../../shared/models/wg-invites.model';
import {WgInviteModel} from '../../../../shared/models/wg-invite.model';

@Injectable({providedIn: 'root'})
export class InviteService extends FirestoreService<WgInvitesModel> {

  COLLECTION = 'invites';

  private invites: { [wgId: string]: WgInviteModel };

  constructor(
    protected store: AngularFirestore
  ) {
    super(store);
  }

  public cancelSubscription() {
    this.invites = null;
    super.cancelSubscription();
  }

  public getInvites(): { [wgId: string]: WgInviteModel } {
    return this.invites;
  }

  public loadInvites(uid: string) {
    return new Promise<any>(resolve => {
      if (this.invites) {
        resolve();
        return;
      }

      this.subscription.push(super.get([uid], [this.COLLECTION]).subscribe((invites: { [wgId: string]: WgInviteModel }) => {
        this.invites = (invites) ? invites : {};
        resolve();
      }));
    });
  }
}
