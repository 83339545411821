import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {

  private date: Date = new Date();

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
  ) {
  }

  canActivate(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth && !auth.isAnonymous) {
          this.router.navigate(['overview']);
          resolve(false);
        }
      });

      resolve(true);
    });
  }
}
