import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {AlertService} from '../../../services/alert.service';
import {UserService} from '../../../services/user.service';
import {WgModel} from '../../../../../../shared/models/wg.model';
import {WgService} from '../../../services/wg.service';
import {WgInviteStateEnum} from '../../../../../../shared/enum/wg-invite-state.enum';
import {RolesEnum} from '../../../../../../shared/enum/roles.enum';
import {WgMemberModel} from '../../../../../../shared/models/wg-member.model';
import {WgMemberService} from '../../../services/wg-member.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-wg-create',
  templateUrl: './wg-create.component.html',
  styleUrls: ['./wg-create.component.scss']
})
export class WgCreateComponent implements OnInit {

  wg: WgModel = {};

  members: WgMemberModel[] = [];

  currentEmail = '';

  loading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private userService: UserService,
    private wgService: WgService,
    private wgMemberService: WgMemberService
  ) {
  }

  ngOnInit() {
    this.members.push({
      id: this.authService.currentUserId(),
      email: this.authService.currentUserEmail(),
      role: RolesEnum.ADMIN,
      state: WgInviteStateEnum.ACCEPTED
    });
  }

  async createWg() {
    this.loading = true;

    if (this.currentEmail.length > 0) {
      this.alertService.showAlert('wg.create.errors.add_member', 5000);
      return;
    }

    const wgId = uuid.v4();
    this.wg.id = wgId;
    this.wg.creator = this.authService.currentUserId();
    this.wg.active = true;

    await this.wgService.createWg(this.wg, wgId);


    for (const member of this.members) {
      member.inviteDate = new Date().getTime();
      await this.wgMemberService.createMember(wgId, member);
    }

    await new Promise(resolve => {
      const interval = setInterval(() => {
        const user = this.userService.getUser();
        if (user.role === RolesEnum.ADMIN) {
          this.alertService.showAlert('wg.create.success', 5000);
          clearInterval(interval);
          resolve();
        }
      }, 500);
    });

    this.router.navigate([''])
  }
}
