import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class AlertService {

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
  }

  showAlert(message: string, time: number, button?: string) {
    this.translate.get(message).subscribe((res: string) => {
      if (button) {
        this.translate.get(button).subscribe((ok: string) => {
          this.snackBar.open(res, ok, {
            duration: time
          });
        });
      } else {
        this.snackBar.open(res, undefined, {
          duration: time
        });
      }
    });
  }

  showAlertWithAction(message: string, time: number, button: string, action: any) {
    this.translate.get(message).subscribe((res: string) => {
      this.translate.get(button).subscribe((ok: string) => {
        this.snackBar.open(res, ok, {
          duration: time
        }).onAction().subscribe(() => {
          action();
        });
      });
    });
  }
}
