import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../../services/alert.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  email: string;
  loading = false;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  forgot() {
    this.loading = true;
    this.authService.forgotPassword(this.email).then(() => {
      this.alertService.showAlert('forgot.success', 5000);
    }).catch(err => {
      this.alertService.showAlert('forgot.success', 5000);
    }).finally(() => {
      this.loading = false;
      this.router.navigate(['']);
    });
  }

  cancel() {
    this.router.navigate(['']);
  }
}
