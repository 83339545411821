import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader} from '@ngx-translate/core';

export const TRANSLATE_LOADER = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const TRANSLATE_CONFIG = {
  loader: {
    provide: TranslateLoader,
    useFactory: TRANSLATE_LOADER,
    deps: [HttpClient]
  }
};
