import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../services/alert.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  password: string;

  repeatPassword: string;

  loading = false;

  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params.oobCode) {
        this.router.navigate(['']);
        return;
      }
      this.token = params.oobCode;
    });

  }

  reset() {
    this.loading = true;
    this.authService.resetPassword(this.token, this.password).then(() => {
      this.alertService.showAlert('reset.success', 5000);
      this.router.navigate(['']);
    }).catch(err => {
      this.alertService.showAlert('reset.error.' + err.code, 5000);
    }).finally(() => {
      this.loading = false;
    });
  }
}
