import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'image-dialog',
    templateUrl: './image-dialog.component.html',
    styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

    image:any;

    constructor(
        private dialogRef: MatDialogRef<ImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

        this.image = data.image;
    }

    ngOnInit() {

    }

    save() {
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }
}
