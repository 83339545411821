import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {RegisterModel} from '../../../../shared/models/register.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CLOUDFUNCTION_URL} from '../shared/config/cloudfunction.config';
import {User} from 'firebase';

@Injectable({providedIn: 'root'})
export class AuthService {

  authState: User = null;

  loaded = false;

  constructor(
    private auth: AngularFireAuth,
    private store: AngularFirestore,
    private httpClient: HttpClient
  ) {
    this.loadAuthentication();
  }

  loadAuthentication(): void {
    this.auth.authState.subscribe((auth) => {
      this.authState = auth;
      this.loaded = true;
    });
  }

  isLoaded() {
    return this.loaded;
  }

  isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false;
  }

  currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  currentUserEmail(): string {
    return (this.authState !== null) ? this.authState.email : '';
  }

  currentUser(): any {
    return (this.authState !== null) ? this.authState : null;
  }

  isUserEmailLoggedIn(): boolean {
    return this.authState !== null;
  }

  register(registerModel: RegisterModel) {
    return this.httpClient.post(CLOUDFUNCTION_URL + 'register', registerModel);
  }

  loginWithEmail(email: string, password: string) {
    return this.auth.auth.signInWithEmailAndPassword(email, password)
      .then((user: any) => {
        this.authState = user;
        return user;
      });
  }

  forgotPassword(email: string) {
    return this.auth.auth.sendPasswordResetEmail(email);
  }

  resetPassword(token: string, newPassword: string) {
    return this.auth.auth.confirmPasswordReset(token, newPassword);
  }

  verifyPasswordReset(actionCode: string) {
    return this.auth.auth.verifyPasswordResetCode(actionCode);
  }

  signOut(): void {
    this.auth.auth.signOut().catch();
  }

  async generateAuthHeader(params?) {
    const token = await this.currentUser().getIdToken(true);

    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + token),
      params
    };
  }
}
