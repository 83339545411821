import {AngularFireStorage} from '@angular/fire/storage';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class StorageService {

  constructor(
    private storage: AngularFireStorage
  ) {}

  async uploadImage(image: any, path: string) {
    if (image && image.files && image.files[0]) {
      const avatar: File = image.files[0];
      const type = avatar.type.split('image/')[1];
      const upload = await this.storage.upload(`${path}.${type}`, avatar);
      return await upload.ref.getDownloadURL();
    }
  }
}
