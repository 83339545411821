import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {AlertService} from '../../../services/alert.service';
import {UserModel} from '../../../../../../shared/models/user.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {YesNoDialogComponent} from 'src/app/shared/yes-no-dialog/yes-no-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-personal',
  templateUrl: './settings-personal.component.html',
  styleUrls: ['./settings-personal.component.scss']
})
export class SettingsPersonalComponent implements OnInit {

  @Input()
  user: UserModel;

  @Input()
  userService: UserService;

  @Input()
  alertService: AlertService;

  avatar: any;

  loading = false;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {

  }

  save() {
    this.loading = true;
    this.userService.updateUser(this.user, this.avatar).then(() => {
      this.loading = false;
      this.alertService.showAlert('settings.personal.success', 5000);
    }).catch(err => {
      this.loading = false;
      this.alertService.showAlert('database.error.' + err.code, 5000);
    });
  }

  configmDeletion() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        title: this.translateService.instant('settings.personal.delete.dialog.title'),
        message: this.translateService.instant('settings.personal.delete.dialog.message')
    };

    this.dialog.open(YesNoDialogComponent, dialogConfig);
  }

  fileChange(fileInput) {
    const files = fileInput.files;
    if (files.length) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.user.avatar = fileReader.result;
      };
      fileReader.readAsDataURL(files[0]);
    }
  }
}
