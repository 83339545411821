import {Routes} from '@angular/router';
import {LoginGuard} from '../../guards/login.guard';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ForgotComponent} from './forgot/forgot.component';
import {ResetComponent} from './reset/reset.component';

export const accountRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [
      LoginGuard
    ],
    pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [
      LoginGuard
    ],
    pathMatch: 'full'
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    canActivate: [
      LoginGuard
    ],
    pathMatch: 'full'
  },
  {
    path: 'reset',
    component: ResetComponent,
    canActivate: [
      LoginGuard
    ],
    pathMatch: 'full'
  }
];
