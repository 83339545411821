import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {AngularFirestore, QueryDocumentSnapshot, QueryFn} from '@angular/fire/firestore';
import {PaymentModel} from '../../../../shared/models/payment.model';
import {StorageService} from './storage.service';
import {last, take} from 'rxjs/operators';
import {PaginationModel} from '../shared/config/pagination.model';
import {DateSearchFilter} from '../shared/search/date-search-filter';
import {SearchOperatorEnum} from '../shared/search/search-operator.enum';

@Injectable({providedIn: 'root'})
export class PaymentService extends FirestoreService<PaymentModel> {

  constructor(
    protected store: AngularFirestore,
    private storageService: StorageService) {
    super(store);
  }

  WG_COLLECTION = 'wgs';

  PAYMENTS_COLLECTION = 'payments';

  LAST_PAYMENT_COUNT = 3;

  pages: Map<number, QueryDocumentSnapshot<PaymentModel>> = new Map<number, QueryDocumentSnapshot<PaymentModel>>();


  getLastPayments(wgId: string) {
    const query: QueryFn = ref => ref.orderBy('createdAt', 'desc').limit(this.LAST_PAYMENT_COUNT);
    return super.getAll([wgId], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION], query).pipe(take(1));
  }

  getPayments(wgId: string, pagination: PaginationModel<PaymentModel>, filter?: DateSearchFilter) {
    let query: any;
    if (!filter.active) {
      if (pagination.page !== 0 && this.pages.get(pagination.page - 1)) {
        query = ref => ref.orderBy(pagination.orderBy, pagination.direction)
          .startAfter(this.pages.get(pagination.page - 1)).limit(pagination.size);
      } else {
        query = ref => ref.orderBy(pagination.orderBy, pagination.direction).limit(pagination.size);
      }
    } else {
      if (pagination.page !== 0 && this.pages.get(pagination.page - 1)) {
        query = ref => ref.where(filter.key, SearchOperatorEnum.GREATER_EQUAL, filter.fromTimestamp)
          .where(filter.key, SearchOperatorEnum.LESS_EQUAL, filter.toTimestamp)
          .orderBy(pagination.orderBy, pagination.direction)
          .startAfter(this.pages.get(pagination.page - 1))
          .limit(pagination.size);
      } else {
        query = ref => ref.where(filter.key, SearchOperatorEnum.GREATER_EQUAL, filter.fromTimestamp)
          .where(filter.key, SearchOperatorEnum.LESS_EQUAL, filter.toTimestamp)
          .orderBy(pagination.orderBy, pagination.direction)
          .limit(pagination.size);
      }
    }

    super.getAllDocument([wgId], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION], query).get().pipe(last()).subscribe(docs => {
      if (!docs.empty) {
        this.pages.set(pagination.page, docs.docs[docs.size - 1]);
      }
    });

    return super.getAll([wgId], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION], query).pipe(take(1));
  }

  async getSearchCount(wgId: string, filter: DateSearchFilter) {
    const query = ref => ref.where(filter.key, SearchOperatorEnum.GREATER_EQUAL, filter.fromTimestamp)
      .where(filter.key, SearchOperatorEnum.LESS_EQUAL, filter.toTimestamp);

    return new Promise<number>(resolve => {
      super.getAllDocument([wgId], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION], query).get().pipe(take(1)).subscribe(result => {
        resolve(result.size);
      });
    });
  }

  async createPayment(wgId: string, payment: PaymentModel, image?: any) {
    payment.id = this.store.createId();
    payment.createdAt = new Date().getTime();

    await this.updateBillImage(wgId, payment, image);
    return super.createWithId(payment, payment.id, [wgId], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION]);
  }

  async updatePayment(wgId: string, payment: PaymentModel, image?: any) {
    await this.updateBillImage(wgId, payment, image);
    return super.update(payment, [wgId, payment.id], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION]);
  }

  async updateBillImage(wgId: string, payment: PaymentModel, image?: any) {
    const billImage = await this.storageService.uploadImage(image, `wgs/${wgId}/payment/${payment.id}/billImage`);
    if (billImage) {
      payment.billImage = billImage;
    } else {
      payment.billImage = '';
    }
  }

  getPayment(wgId: string, id: string) {
    return super.get([wgId, id], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION]).pipe(take(1));
  }

  async deletePayment(wgId: string, paymentId: string) {
    return super.delete([wgId, paymentId], [this.WG_COLLECTION, this.PAYMENTS_COLLECTION]);
  }
}
