import {Routes} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import {OverviewComponent} from './overview.component';

export const overviewRoutes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full',
  }
];
