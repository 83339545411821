import {Routes} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import {WgCreateComponent} from './wg-create/wg-create.component';

export const wgRoutes: Routes = [
  {
    path: 'wg/create',
    component: WgCreateComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full'
  }
];
