import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WgModel} from '../../../../../../../shared/models/wg.model';
import {RolesEnum} from '../../../../../../../shared/enum/roles.enum';
import {WgInviteStateEnum} from '../../../../../../../shared/enum/wg-invite-state.enum';
import {WgMemberModel} from '../../../../../../../shared/models/wg-member.model';

@Component({
  selector: 'app-wg-manage-user',
  templateUrl: './wg-manage-user.component.html',
  styleUrls: ['./wg-manage-user.component.scss']
})
export class WgManageUserComponent implements OnInit {

  @Input()
  wg: WgModel;

  @Input()
  members: WgMemberModel[];

  @Input()
  canEdit: boolean;

  @Input()
  email: string;

  @Input()
  editableRole: RolesEnum = null;

  @Output()
  removeEmailEvent = new EventEmitter<number>();

  RoleEnum = RolesEnum;

  WgInviteStateEnum = WgInviteStateEnum;

  constructor() {
  }

  ngOnInit() {
  }

  removeEmail(index: number) {
    this.removeEmailEvent.emit(index);
  }

  isOwnEmail(email: string) {
    return email === this.email;
  }
}
