import {Component, Input, OnInit} from '@angular/core';
import {PaymentModel} from '../../../../../../shared/models/payment.model';
import {PaymentService} from '../../../services/payment.service';
import {UserModel} from '../../../../../../shared/models/user.model';
import {WgMemberModel} from '../../../../../../shared/models/wg-member.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-last-payments',
  templateUrl: './last-payments.component.html',
  styleUrls: ['./last-payments.component.scss']
})
export class LastPaymentsComponent implements OnInit {

  @Input()
  user: UserModel;

  @Input()
  members: WgMemberModel[];

  @Input()
  parentLoading = true;

  payments: PaymentModel[];

  loading = true;

  constructor(
    private router: Router,
    private paymentService: PaymentService
  ) {}

  getPriceText(price: number) {
    return (price / 100.0).toFixed(2) + ' €';
  }

  ngOnInit() {
    this.loadLastPayments();
  }

  loadLastPayments() {
    this.paymentService.getLastPayments(this.user.wgId).subscribe(payments => {
      this.payments = payments;
      this.loading = false;
    });
  }

  getDate(timestamp) {
    const date = new Date();
    date.setTime(timestamp * 1000);
    return date;
  }

  getMemberById(id) {
    return this.members.find(entity => {
      return entity.id === id;
    });
  }

  onPaymentsOverviewClick() {
    this.router.navigate(['/payments/overview']);
  }

  onPaymentClick(payment: PaymentModel) {
    this.router.navigate(['/payments/view/' + payment.id]);
  }
}
