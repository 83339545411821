import {Component, OnInit} from '@angular/core';
import {UserModel} from '../../../../../../shared/models/user.model';
import {WgMemberModel} from '../../../../../../shared/models/wg-member.model';
import {PaymentModel} from '../../../../../../shared/models/payment.model';
import {PaymentService} from 'src/app/services/payment.service';
import {UserService} from 'src/app/services/user.service';
import {WgMemberService} from 'src/app/services/wg-member.service';
import {AuthService} from 'src/app/services/auth.service';
import {AlertService} from 'src/app/services/alert.service';
import {WgService} from '../../../services/wg.service';
import {WgModel} from '../../../../../../shared/models/wg.model';
import {PaginationModel} from '../../../shared/config/pagination.model';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {EntityFactoryService} from '../../../services/entity-factory.service';
import {EntitiesEnum} from '../../../../../../shared/enum/entities.enum';
import {DateSearchFilter} from '../../../shared/search/date-search-filter';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-payments-overview',
  templateUrl: './payments-overview.component.html',
  styleUrls: ['./payments-overview.component.scss']
})
export class PaymentsOverviewComponent implements OnInit {

  displayedColumns: string[] = [
    'date', 'from', 'to', 'amount',  'comment',  'actions'
  ];

  payments: PaymentModel[] = [];

  paginationCount: number;

  pagination: PaginationModel<PaymentModel> = {
    size: 10,
    page: 0,
    orderBy: 'date',
    direction: 'desc'
  };

  loading = true;

  filter: DateSearchFilter = {
    active: false,
    key: 'date',
    to: null,
    toTimestamp: 0,
    from: null,
    fromTimestamp: 0
  };

  constructor(
    private paymentService: PaymentService,
    private userService: UserService,
    private wgService: WgService,
    private authService: AuthService,
    private alertService: AlertService,
    private wgMemberService: WgMemberService,
    private entityFactory: EntityFactoryService,
    private router: Router,
    private datePipe: DatePipe
  ) {
  }

  getPriceText(price: number) {
    return (price / 100.0).toFixed(2) + ' €';
  }

  async ngOnInit(): Promise<void> {
    await this.entityFactory.loadEntities([EntitiesEnum.USER, EntitiesEnum.WG_MEMBER]);
    this.paginationCount = this.wg.paymentCount;
    this.loadPayments();
  }

  loadPayments() {
    this.paymentService.getPayments(this.user.wgId, this.pagination, this.filter).pipe().subscribe(payments => {
      this.payments = payments;
      this.loading = false;
    });
  }

  onPageChange(page: PageEvent) {
    if (this.pagination.size !== page.pageSize) {
      this.pagination.size = page.pageSize;
      this.pagination.page = 0;
    } else {
      this.pagination.page = page.pageIndex;
    }
    this.loadPayments();
  }


  onSortChange(sort: Sort) {
    this.pagination.direction = sort.direction === 'desc' ?  'desc' : 'asc';
    this.pagination.orderBy = sort.active;
    this.pagination.page = 0;
    this.loadPayments();
  }

  getDate(timestamp) {
    const date = new Date();
    date.setTime(timestamp * 1000);
    return date;
  }

  getMemberNameById(id) {
    const member = this.getMemberById(id);
    return member.forename + ' ' + member.surname;
  }

  getMembersNameByIds(ids) {
    let names = '';
    for (const id of ids) {
      names += this.getMemberNameById(id) + ', ';
    }

    return names.slice(0, names.length - 2);
  }

  getMemberById(id) {
    return this.members.find(entity => {
      return entity.id === id;
    });
  }

  editPayment(payment: PaymentModel): void {
    this.router.navigate([`/payments/view/${payment.id}`]);
  }

  canEdit(payment: PaymentModel): boolean {
    return payment.from === this.user.id;
  }

  clearFilter() {
    this.filter.active = false;
    this.paginationCount = this.wg.paymentCount;
    this.loadPayments();
  }

  async dateFilterChanged() {
    this.filter.active = true;
    this.filter.from.setHours(0, 0, 0, 0);
    this.filter.to.setHours(23, 59, 29, 999);
    this.filter.fromTimestamp = Math.round(this.filter.from.getTime() / 1000);
    this.filter.toTimestamp = Math.round(this.filter.to.getTime() / 1000);
    this.paginationCount = await this.paymentService.getSearchCount(this.wg.id, this.filter);
    this.loadPayments();
  }

  parseValue(filter: DateSearchFilter) {
    const from = this.datePipe.transform(filter.from, 'dd.MM.yyyy');
    const to = this.datePipe.transform(filter.to, 'dd.MM.yyyy');
    return from + ' - ' + to;
  }

  get user(): UserModel {
    return this.userService.getUser();
  }

  get members(): WgMemberModel[] {
    return this.wgMemberService.getMembers();
  }

  get wg(): WgModel {
    return this.wgService.getWg();
  }
}
