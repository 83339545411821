import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent implements OnInit {

  @Input()
  lastPage = false;

  constructor(
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
  }

  overview() {
    if (!this.lastPage) {
      this.router.navigate(['/overview']);
    } else {
      this.location.back();
    }
  }
}
