import {Routes} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import {NotificationsComponent} from './notifications.component';

export const notificationsRoutes: Routes = [
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [
      AuthGuard
    ],
    pathMatch: 'full'
  }
];
