import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {InviteService} from '../../services/invite.service';
import {AlertService} from '../../services/alert.service';
import {WgModel} from '../../../../../shared/models/wg.model';
import {WgService} from '../../services/wg.service';
import {UserModel} from '../../../../../shared/models/user.model';
import {WgInviteModel} from '../../../../../shared/models/wg-invite.model';
import {RolesEnum} from '../../../../../shared/enum/roles.enum';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {EntityFactoryService} from '../../services/entity-factory.service';
import {EntitiesEnum} from '../../../../../shared/enum/entities.enum';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  ONE_SECOND = 1000;
  ONE_MINUTE = 60 * this.ONE_SECOND;
  ONE_HOUR = 60 * this.ONE_MINUTE;
  ONE_DAY = 24 * this.ONE_HOUR;
  ONE_MONTH = 31 * this.ONE_DAY;
  ONE_YEAR = 365 * this.ONE_DAY;

  GRADIENT_TEXT = '         ';

  wgMap: { [wgId: string]: WgModel } = {};

  loading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private invitesService: InviteService,
    private alertService: AlertService,
    private wgService: WgService,
    private translateService: TranslateService,
    private entityFactory: EntityFactoryService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.entityFactory.loadEntities([EntitiesEnum.USER, EntitiesEnum.NOTIFICATIONS]);
    this.loadWgsForInvitation();
  }

  getPassedTimeString(timestamp: number) {
    const now = new Date();
    const passedTime = new Date(now.getTime() - timestamp).getTime();
    if (passedTime > this.ONE_YEAR && passedTime % this.ONE_YEAR > 1) {
      return this.getTranslation('time.year-ago');
    } else if (passedTime > this.ONE_MONTH && passedTime % this.ONE_MONTH > 1) {
      const months = Math.round(passedTime / this.ONE_MONTH);
      if (months === 1) {
        return this.getTranslation('time.month-ago');
      } else {
        return this.getTranslation('time.months-ago', {month: months});
      }
    } else if (passedTime > this.ONE_DAY && passedTime % this.ONE_DAY > 1) {
      const days = Math.round(passedTime / this.ONE_DAY);
      if (days === 1) {
        return this.getTranslation('time.day-ago');
      } else {
        return this.getTranslation('time.days-ago', {day: days});
      }
    } else if (passedTime > this.ONE_HOUR && passedTime % this.ONE_HOUR > 1) {
      const hours = Math.round(passedTime / this.ONE_HOUR);
      if (hours === 1) {
        return this.getTranslation('time.hour-ago');
      } else {
        return this.getTranslation('time.months-ago', {hour: hours});
      }
    } else if (passedTime > this.ONE_MINUTE && passedTime % this.ONE_MINUTE > 1) {
      const minutes = Math.round(passedTime / this.ONE_MINUTE);
      if (minutes === 1) {
        return this.getTranslation('time.minute-ago');
      } else {
        return this.getTranslation('time.minutes-ago', {minute: minutes});
      }
    } else if (passedTime > this.ONE_SECOND && passedTime % this.ONE_SECOND > 1) {
      const seconds = Math.round(passedTime / this.ONE_SECOND);
      if (seconds === 1) {
        return this.getTranslation('time.second-ago');
      } else {
        return this.getTranslation('time.seconds-ago', {second: seconds});
      }
    }

  }

  getTranslation(text: string, params?: any) {
    return this.translateService.instant(text, params);
  }

  acceptInvitation(wgId: string) {
    this.loading = true;
    this.wgService.handleInviteAction(wgId, true).then(() => {
      if (this.user.role !== RolesEnum.USER) {
        this.alertService.showAlert('notifications.accepted', 5000);
        this.loading = false;
      }
    }).catch(err => {
      this.alertService.showAlert('database.error.' + err.code, 5000);
      alert(JSON.stringify(err));
    });
  }

  declineInvitation(wgId: string) {
    this.loading = true;
    this.wgService.handleInviteAction(wgId, false).then(() => {
      this.alertService.showAlert('notifications.declined', 5000);
      this.loading = false;
    }).catch(err => {
      this.alertService.showAlert('database.error.' + err.code, 5000);
    });
  }

  get user(): UserModel {
    return this.userService.getUser();
  }

  get invites(): { [wgId: string]: WgInviteModel } {
    return this.invitesService.getInvites();
  }

  private loadWgsForInvitation() {
    for (const wgId of Object.keys(this.invites)) {
      this.wgService.getExternalWg(wgId).pipe(take(1)).subscribe(wg => {
        this.wgMap[wgId] = wg;
      }, err => {
        console.log(err);
        this.alertService.showAlert('database.error.' + err.code, 5000);
      });
    }
  }
}
