import {Injectable} from '@angular/core';
import {AccumulationService} from './accumulation.service';
import {InviteService} from './invite.service';
import {UserService} from './user.service';
import {WgService} from './wg.service';
import {WgMemberService} from './wg-member.service';
import {EntitiesEnum} from '../../../../shared/enum/entities.enum';

@Injectable({providedIn: 'root'})
export class EntityFactoryService {

  constructor(
    private accumulationService: AccumulationService,
    private inviteService: InviteService,
    private userService: UserService,
    private wgService: WgService,
    private wgMemberService: WgMemberService
  ) {
  }


  public async loadEntities(entities: EntitiesEnum[]) {
    let wgId = null;
    let userId = null;

    for (const entity of entities) {
      switch (entity) {
        case EntitiesEnum.ACCUMULATION:
          if (!wgId) {
            break;
          }

          await this.accumulationService.loadAccumulations(wgId, userId);
          break;
        case EntitiesEnum.NOTIFICATIONS:
          await this.inviteService.loadInvites(userId);
          break;
        case EntitiesEnum.USER:
          await this.userService.loadUser();
          userId = this.userService.getUser().id;
          wgId = this.userService.getUser().wgId;
          break;
        case EntitiesEnum.WG:
          if (!wgId) {
            break;
          }
          await this.wgService.loadWg(wgId);
          break;
        case EntitiesEnum.WG_MEMBER:
          if (!wgId) {
            break;
          }

          await this.wgMemberService.loadWgMembers(wgId);
          break;
      }
    }
  }
}
