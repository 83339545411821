import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {WgService} from '../../../services/wg.service';
import {WgModel} from '../../../../../../shared/models/wg.model';
import {AlertService} from '../../../services/alert.service';
import {UserModel} from '../../../../../../shared/models/user.model';
import {RolesEnum} from '../../../../../../shared/enum/roles.enum';
import {WgMemberModel} from '../../../../../../shared/models/wg-member.model';
import {WgMemberService} from '../../../services/wg-member.service';
import {WgInviteStateEnum} from '../../../../../../shared/enum/wg-invite-state.enum';

@Component({
  selector: 'app-settings-wg',
  templateUrl: './settings-wg.component.html',
  styleUrls: ['./settings-wg.component.scss']
})
export class SettingsWgComponent implements OnInit {

  @Input()
  user: UserModel;

  @Input()
  wg: WgModel;

  members: WgMemberModel[];

  RoleEnum = RolesEnum;

  loading = false;

  constructor(
    private router: Router,
    private wgService: WgService,
    private alertService: AlertService,
    private wgMemberService: WgMemberService,
  ) {
  }

  async ngOnInit() {
    this.members = this.wgMemberService.getMembers().filter((member: WgMemberModel) => {
      return member.state !== WgInviteStateEnum.DECLINED && member.role !== RolesEnum.DELETED;
    });
  }

  onCreateWgClicked() {
    this.router.navigate(['/wg/create']);
  }

  async save() {
    this.loading = true;
    await this.wgService.updateWg(this.wg);

    for (const member of this.members) {
      member.inviteDate = new Date().getTime();
      await this.wgMemberService.createMember(this.wg.id, member);
    }

    this.alertService.showAlert('wg.update.success', 5000);
    this.loading = false;
  }
}
