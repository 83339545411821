import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WgModel} from '../../../../../../shared/models/wg.model';
import {RolesEnum} from '../../../../../../shared/enum/roles.enum';
import {AlertService} from '../../../services/alert.service';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {WgInviteStateEnum} from '../../../../../../shared/enum/wg-invite-state.enum';
import {WgMemberModel} from '../../../../../../shared/models/wg-member.model';

@Component({
  selector: 'wg-app-manage',
  templateUrl: './wg-manage.component.html',
  styleUrls: ['./wg-manage.component.scss']
})
export class WgManageComponent implements OnInit {

  @Input()
  wg: WgModel;

  @Input()
  members: WgMemberModel[];

  @Input()
  submitButtonText: string;

  @Input()
  editableRole: RolesEnum = null;

  @Output()
  submitEvent: EventEmitter<void> = new EventEmitter();

  email = '';

  addLoading = false;

  loading = false;

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private authService: AuthService
  ) {
  }

  get canEdit() {
    if (!this.editableRole) {
      return true;
    }

    const member = this.getMemberWithEmail(this.authService.currentUserEmail());

    return member && member.role === this.editableRole;
  }

  ngOnInit() {
  }

  getMemberWithEmail(email: string) {
    return this.members.find(element => {
      return element.email === email;
    });
  }

  addEmail() {
    this.addLoading = true;
    if (this.isOwnEmail(this.email)) {
      this.addLoading = false;
      this.alertService.showAlert('wg.create.errors.own_email', 5000);
      return;
    }
    if (this.alreadyAdded()) {
      this.addLoading = false;
      this.alertService.showAlert('wg.create.errors.duplicate', 5000);
      return;
    }
    this.userService.userExists(this.email).then(res => {
      if (res.exists && res.role === RolesEnum.USER) {
        this.members.push({
          id: res.id,
          email: this.email,
          role: RolesEnum.MEMBER,
          state: WgInviteStateEnum.OPEN
        });
      } else if (res.exists && res.role !== RolesEnum.USER) {
        this.alertService.showAlert('wg.create.errors.not_addable', 5000);
      } else {
        this.alertService.showAlert('wg.create.errors.not_found', 5000);
      }
      this.addLoading = false;
    });
  }

  isOwnEmail(email: string) {
    return email === this.authService.currentUserEmail();
  }

  removeEmail(index: number) {
    this.members[index].role = RolesEnum.DELETED;
  }

  alreadyAdded() {
    const member: WgMemberModel = this.getMemberWithEmail(this.email);
    return member !== undefined && member.state !== WgInviteStateEnum.DECLINED && member.role !== RolesEnum.DELETED;
  }
}
