import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {EntityFactoryService} from '../services/entity-factory.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private entityFactory: EntityFactoryService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.afAuth.authState.subscribe(async (auth) => {
        if (!auth || auth.isAnonymous) {
          this.router.navigate(['']);
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }
}
