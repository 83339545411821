import {Injectable} from '@angular/core';
import {WgMemberModel} from '../../../../shared/models/wg-member.model';
import {FirestoreService} from './firestore.service';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({providedIn: 'root'})
export class WgMemberService extends FirestoreService<WgMemberModel> {

  private WG_COLLECTION = 'wgs';

  private MEMBERS_COLLECTION = 'members';

  private wgMembers: WgMemberModel[];

  constructor(
    protected store: AngularFirestore,
  ) {
    super(store);
  }

  public cancelSubscription() {
    this.wgMembers = null;
    super.cancelSubscription();
  }

  public getMembers() {
    return this.wgMembers;
  }

  public loadWgMembers(wgId: string) {
    return new Promise(resolve => {
      if (this.wgMembers) {
        resolve();
        return;
      }

      const query = ref => ref.orderBy('inviteDate', 'asc');
      this.subscription.push(super.getAll([wgId], [this.WG_COLLECTION, this.MEMBERS_COLLECTION], query).subscribe((wgMember: WgMemberModel[]) => {
        this.wgMembers = wgMember;
        resolve();
      }));
    });
  }

  public createMember(wgId: string, member: WgMemberModel) {
    return super.createWithId(member, member.id, [wgId], [this.WG_COLLECTION, this.MEMBERS_COLLECTION]);
  }
}
