import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryFn} from '@angular/fire/firestore';
import {BaseEntityModel} from '../../../../shared/models/base-entity.model';
import {Subscription} from 'rxjs';

export abstract class FirestoreService<T extends BaseEntityModel> {

  protected subscription: Subscription [] = [];

  protected constructor(
    protected store: AngularFirestore,
  ) {
  }

  public cancelSubscription() {
    for (const sub of this.subscription) {
      sub.unsubscribe();
    }
  }

  protected get(ids: string[], collections: string[]) {
    const doc: AngularFirestoreDocument = this.getDocument(ids, collections);
    return doc.valueChanges();
  }

  protected getAll(ids: string[], collections: string[], query: QueryFn) {
    const collection =  this.getAllDocument(ids, collections, query);
    return collection.valueChanges();
  }

  protected update(model: T, ids: string[], collections: string[]) {
    const doc: AngularFirestoreDocument = this.getDocument(ids, collections);

    return doc.update(model);
  }

  protected delete(ids: string[], collections: string[]) {
    const doc: AngularFirestoreDocument = this.getDocument(ids, collections);
    return doc.delete();
  }

  protected createWithId(model: T, id: string, ids: string[], collections: string[]) {
    const doc: AngularFirestoreDocument = this.getDocument([...ids, id], collections);
    return doc.set(model);
  }

  protected create(model: T, ids: string[], collections: string[]) {
    let collection: AngularFirestoreCollection = null;

    for (let i = 0; i < collections.length; i++) {
      if (!collection) {
        collection = this.store.collection(collections[i]);
      } else {
        collection = collection.doc(ids[i - 1]).collection(collections[i]);
      }
    }

    return collection.add(model);
  }

  protected getAllDocument(ids: string[], collections: string[], query: QueryFn) {
    let collection: AngularFirestoreCollection = null;

    for (let i = 0; i < collections.length; i++) {
      if (!collection) {
        collection = this.store.collection(collections[i]);
      } else if (i < collections.length - 1 || !query) {
        collection = collection.doc(ids[i - 1]).collection(collections[i]);
      } else {
        collection = collection.doc(ids[i - 1]).collection(collections[i], query);
      }
    }

    return collection;
  }

  private getDocument(ids: string[], collections: string[]) {
    let doc: AngularFirestoreDocument = null;

    for (let i = 0; i < collections.length; i++) {
      if (!doc) {
        doc = this.store.collection(collections[i]).doc(ids[i]);
      } else {
        doc = doc.collection(collections[i]).doc(ids[i]);
      }
    }

    return doc;
  }
}
