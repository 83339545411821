import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {AlertService} from '../../services/alert.service';
import {RolesEnum} from '../../../../../shared/enum/roles.enum';
import {WgMemberService} from '../../services/wg-member.service';
import {AccumulationService} from '../../services/accumulation.service';
import {EntityFactoryService} from '../../services/entity-factory.service';
import {EntitiesEnum} from '../../../../../shared/enum/entities.enum';
import {WgMemberModel} from '../../../../../shared/models/wg-member.model';
import {WgInviteStateEnum} from '../../../../../shared/enum/wg-invite-state.enum';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  RoleEnum = RolesEnum;

  loading = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private wgMemberService: WgMemberService,
    private alertService: AlertService,
    private accumulationService: AccumulationService,
    private entityFactory: EntityFactoryService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.entityFactory.loadEntities([EntitiesEnum.USER, EntitiesEnum.WG_MEMBER, EntitiesEnum.ACCUMULATION]);
    this.loading = false;
  }
  onCreateWgClicked() {
    this.router.navigate(['/wg/create']);
  }

  onAddPaymentClick(): void {
    this.router.navigate(['/payments/create']);
  }

  get user() {
    return this.userService.getUser();
  }

  get members(): WgMemberModel[] {
    return this.wgMemberService.getMembers();
  }

  get activeMembers() {
    if (!this.members) {
      return null;
    }

    return this.members.filter((member: WgMemberModel) => {
      return member.state === WgInviteStateEnum.ACCEPTED;
    });
  }

  get accumulations() {
    return this.accumulationService.getAccumulations();
  }
}
